import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Estelle Sartere`}</strong>{` is a member of the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{` in `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{` investigating the `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{` that have been going missing.`}</p>
    <p>{`She has a niece, `}<a parentName="p" {...{
        "href": "/Valent%20Childs",
        "title": "Valent Childs"
      }}>{`Valent Childs`}</a>{`, who is a scion of the `}<a parentName="p" {...{
        "href": "/Primal%20Spirit%20of%20Soul",
        "title": "Primal Spirit of Soul"
      }}>{`Primal Spirit of Soul`}</a>{`. Valent's manifestations of Soul were becoming dangerous as she got older, so Estelle went to the `}<a parentName="p" {...{
        "href": "/Saetorim%20Institute",
        "title": "Saetorim Institute"
      }}>{`Saetorim Institute`}</a>{` at a time when `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sulazar`}</a>{` still worked there, and asked them for help.`}</p>
    <p>{`Now, Valent has gone missing along with many other `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{` in `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`. Estelle has mounted an investigation, using the full might of the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{` behind her to bring Valent back and bring justice to whomever is kidnapping people.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      